html,
body,
#__next {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

main {
  background-color: $light-gray;
}

.required::after {
  content: '*';
  margin-left: 0.25rem;
  color: $red;
}

.bg-linear {
  background: linear-gradient(
    90deg,
    #84257a -0.23%,
    #86257a 0.77%,
    #ab1e70 11.84%,
    #c81969 22.9%,
    #dc1563 33.97%,
    #e91360 45.03%,
    #ed125f 55.09%,
    #ee1e5b 63.14%,
    #f03e50 78.23%,
    #f3723e 97.34%,
    #f47b3b 100.36%
  );
}

.sorting {
  cursor: pointer;
  user-select: none;
}

.btn-circle {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  padding: 0;
  border-radius: 50%;

  svg {
    position: relative;
    top: -1px;
  }
}

legend.primary {
  color: $primary;
  font-weight: 700;
}

legend.secondary {
  color: #f3723e;
  font-weight: 600;
}

.text-gray {
  color: $gray-600;
}

.btn-gray {
  color: #fff;
  background-color: $gray-600;
  border-color: $gray-600;
}

fieldset {
  margin-bottom: 1rem;
}

.sticky-table {
  position: relative;
  z-index: 1;
  overflow: auto;
  max-height: 30rem;
  width: 100%;
  margin: auto;

  table {
    width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;

    thead th {
      background: white;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
      z-index: 4;
      border-top-width: 1px;
      border-bottom-width: 1px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.modal-60w {
  max-width: 60vw;

  @media (max-width: 992px) {
    max-width: 100%;
  }
}

.tr-text-white > * {
  color: $white !important;
}

.react-window-no-results,
.react-window-loading {
  text-align: center;
  color: hsl(0, 0%, 60%);
  padding: 8px 12px;
}

.disabled-card {
  background-color: #dee2e6;
  pointer-events: none;
  cursor: not-allowed;
  border: 3px solid #c1c8d0;
  opacity: 0.75;
}
