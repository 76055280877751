@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__day--selected {
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: $primary;
    opacity: 0.6;
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: $primary;
  color: $white;
  opacity: 0.6;

  &:hover {
    background-color: $primary;
    opacity: 0.6;
  }
}

.react-datepicker__header,
.react-datepicker__today-button {
  background-color: #f3f5f7;
}
