// TODO: Sync up with the symfony/assets/bootstrap-theme

// Colors
$primary: #c81969;
$secondary: #f47b3b;
$red: #c82019;
$orange: $secondary;
$black: #212529;
$gray-300: #dee2e6;
$light-gray: #f3f5f7;
$white: #fff;

// Cards
$card-border-width: 0;
$card-border-radius: 0.85rem;

// Breadcrumbs
$breadcrumb-border-radius: 0.75rem;

// Alerts
$alert-border-width: 0;
$alert-border-radius: 0.75rem;

// Pagination
$pagination-color: $black;
$pagination-active-color: $primary;
$pagination-active-bg: $white;
$pagination-active-border-color: $gray-300;

// Form file input
$form-file-button-bg: $primary;
$form-file-button-color: $white;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
